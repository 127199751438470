
import React from 'react'
import Reports from '../../../templates/reportes'

const ReportsEsPage = () => {
    return (
        <Reports lang="es">
        </Reports>
    )
}

export default ReportsEsPage
